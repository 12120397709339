import React from "react";
import { graphql } from "gatsby";
import classnames from "classnames";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Slice from "../components/slice";
import PageTitle from "../components/pagetitle";

const PageTemplate = ({ data }) => {
  const {
    meta_title,
    meta_description,
    page_title,
    body
  } = data.prismicPage.data;

  return (
    <Layout>
      <SEO title={meta_title} description={meta_description} />
      <div className="max-w-3xl mx-auto w-full">
        <PageTitle text={page_title.text} />
      </div>
      <div className="flex flex-col md:flex-row w-full max-w-3xl mx-auto">
        <div>
          {body.map((slice, index) => (
            <Slice key={index} {...slice} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PageByUid($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        meta_title
        meta_description
        page_title {
          text
        }
        body {
          ... on PrismicPageBodyImage {
            primary {
              image {
                url
                alt
              }
            }
            slice_type
          }
          ... on PrismicPageBodyRichtext {
            primary {
              rich_text {
                html
              }
            }
            slice_type
          }
          ... on PrismicPageBody5050 {
            slice_type
            primary {
              section_one {
                html
              }
              section_two {
                html
              }
            }
          }
          ... on PrismicPageBodyImageGallery {
            slice_type
            items {
              image {
                url
                dimensions {
                  height
                  width
                }
                thumbnails {
                  full_width {
                    url
                  }
                  thumbnail {
                    url
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  }
`;

export default PageTemplate;
